@import './styles/colors';

.main-layout-dashboard {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 5px;

  .btn-add-new {
    margin: 10px;
  }

  .dx-toolbar {
    background-color: rgba(191, 191, 191, 0.15);
    padding: 5px 10px;
  }

  .dx-list-item-icon-container,
  .dx-toolbar-before {
    width: 36px;
    padding-right: 0 !important;
    text-align: center;
  }

  .dx-list-item-content {
    padding-left: 10px !important;
  }

  .list {
    height: inherit;
    padding-bottom: 45px;
  }

  .dx-drawer-expand.dx-drawer-right .panel-list {
    float: right;
  }

  .panel-list .dx-list-item {
    color: #fff;
    border-top: 1px solid rgba(221, 221, 221, 0.2);
  }

  .panel-list .dx-list-item .dx-icon {
    color: #fff !important;
  }

  .options {
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
  }

  .caption {
    font-size: 18px;
    font-weight: 500;
  }

  .option {
    margin-top: 10px;
    display: inline-block;
    margin-right: 50px;
  }

  #content {
    padding: 10px 20px;
  }

  .dx-drawer {
    height: 100% !important;
  }
}

.autocomplete-loader-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;

  & input:is(autocomplete-loader--show) {
    padding-right: 2rem !important;
    border: 1px solid red;
  }
}

.autocomplete-loader {
  position: absolute;
  right: 70px;
  top: 50%;
  transform: translateY(-50%);
  display: none;

  &--hide {
    display: none;
  }

  &--show {
    display: block;
  }
}

.dx-data-row {
  &.blackListed {
    background-color: color('primary') !important;
  }
}

.dx-button {
  &.dx-button-mode-contained {
    background-color: color('primary') !important;
    color: color('white-color') !important;

    &.selected-page {
      background-color: #2f3b8a !important;
    }
  }

  &.dx-button-mode-contained:hover {
    background-color: color('primary-hover') !important;

    &.selected-page {
      background-color: #2f3b8a !important;
    }
  }

  & .dx-button-content {
    & .dx-icon {
      color: color('white-color') !important;
    }

    & .dx-icon-close {
      color: color('primary-hover') !important;
    }

  }
}

.dx-link {
  color: color('primary') !important;
}

.dx-scrollable-wrapper {
  position: relative;
  width: 100%;
  height: 80vh !important;
}

