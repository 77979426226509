@import '../../styles/colors';

.main-container {
  & .dx-drawer-panel-content {
    height: inherit;
    background-color: color('sidebar-bg');
    height: 100%;
    min-height: 100vh;
  }

  & .dx-treeview-node {
    padding-left: 0;
  }

  & .dx-treeview .dx-scrollable-wrapper {
    color: color('secondary');
    padding: 0 20px;
    margin-top: 30px;
  }

  & .dx-drawer-shrink .dx-drawer-panel-content {
    height: auto;
  }

  & .dx-treeview-toggle-item-visibility {
    left: unset;
    right: 0;
    padding: 0 2rem;


    &::before {
      color: color('secondary');
      font-size: 1.5rem;
      margin: 0 auto;
    }
  }

  & .dx-treeview-node-container {
    & .dx-treeview-node {
      margin-top: 5px;
    }

    & .dx-treeview-node-container .dx-treeview-item-without-checkbox {

      & .dx-item {
        margin-left: 22px;
      }
    }

    & .dx-item-content {
      padding: 8px 0;
    }

    & .dx-state-hover {
      background-color: color('primary-light') !important;
      border-radius: 5px;
      filter: invert(38%) sepia(99%) saturate(314%) hue-rotate(193deg) brightness(90%) contrast(92%);
    }

    & .dx-state-selected {
      border-radius: 5px;
      filter: invert(38%) sepia(99%) saturate(314%) hue-rotate(193deg) brightness(90%) contrast(92%);

      & .dx-item-content {
        color: color('primary') !important;
      }

      & .dx-treeview-item {
        border-radius: 5px;
      }

      & .dx-icon-publisher,
      .dx-icon-reports-icon,
      .dx-icon-transactions-icon,
      .dx-icon-pricing-icon,
      .dx-icon-content-agent,
      .dx-icon-client-orders {
        background-color: color('white-color');
      }
    }
  }

  .dx-state-hover {
    .dx-icon-publisher,
    .dx-icon-reports-icon,
    .dx-icon-transactions-icon,
    .dx-icon-pricing-icon,
    .dx-icon-content-agent,
    .dx-icon-client-orders {
      background-color: #333;
    }
  }
}

.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item {
  background-color: color('primary-light') !important;
  border-radius: 5px;
  filter: invert(38%) sepia(99%) saturate(314%) hue-rotate(193deg) brightness(90%) contrast(92%);
  color: #fff;
}

.dx-button-mode-contained {
  background-color: color('primary') !important;
  color: color('white-color') !important;

  & .dx-dropdowneditor-icon {
    color: color('white-color') !important;

    &:hover {
      color: color('primary') !important;
    }
  }

  &:hover {
    background-color: color('primary-hover') !important;
    border-color: color('primary-hover') !important;
  }
}

.dx-button-text {
  color: color('white-color') !important;
}

.aside-container {
  height: 100%;
  max-height: 100vh;
  position: fixed;
  display: flex;
  flex-direction: column;

  &__logo {
    width: 250px;
    display: block;
    padding: 1rem 20px;
    margin-bottom: 3rem;
    // filter: drop-shadow(1px 1px 1px rgba(0, 0, 0));
  }
}

.main-app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.aside-logo-text {
  font-weight: bold !important;
  padding-top: 15px;
  color: color('header-logo-text') !important;
  font-size: 2rem !important;
  text-align: center !important;
  margin-bottom: 1rem !important;

  .aside-logo-icon{
    transform: scale(1.3);
  }
}

.header-collapsed {
  color: color('primary') !important;
  .header-collapsed-icon {
    transform: scale(1.2);
  }
}

.header__logout-btn {
  background-color: color('primary') !important;
  color: color('primary') !important;
}


