.paging-section {
  display: grid;
  grid-template-columns:1fr auto auto;
  align-items: center;

  padding: 5px 0;

  .paging-buttons {
    display: grid;
    grid-template-columns: repeat(11, auto);
    justify-items: end;
    justify-content: end;
    align-self: center;
    column-gap: 2px;
  }

  .total-records {
    border: 1px solid #cccccc;
    border-radius: 9px;
    padding: 9px;
  }

  .lastPage {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
  }

  .firstPage {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: baseline;
  }
}

.selected-page {
  background-color: #f0f0f0 !important;
}
