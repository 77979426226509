@import '../../styles/colors';

.grid {
  &__header {
    margin: 10px 0;
  }
}

$grid-border-width: 2px;
.grid-table,
.dx-gridbase-container {
  .dx-datagrid-rowsview {
    border: unset;
  }

  .dx-datagrid-borders > .dx-datagrid-filter-panel,
  .dx-datagrid-borders > .dx-datagrid-headers {
    border: unset;
  }

  .dx-datagrid-borders .dx-datagrid-headers .dx-datagrid-table {
    .dx-datagrid-borders > .dx-datagrid-filter-panel,
    .dx-datagrid-borders > .dx-datagrid-headers {
      border: unset;
    }

    .dx-row.dx-header-row {
      border: $grid-border-width solid color("border-color");
      border-top: unset;
      border-left: unset;
      border-right: unset;

      background: color('dark-gray');
      color: color('text-color');
      font-weight: 600;
    }
  }

  .dx-datagrid-content {
    tbody > tr {
      &:nth-child(even) {
        background: color('sidebar-bg');
        color: color('text-color');
      }

      & > td:not(:last-child) {
        padding: 10px 5px;
      }

      &:first-child {
        border-top: unset;
      }
    }
  }

  & .dx-datagrid-table .dx-header-row {
    & td.dx-command-expand {
      border-right: none !important;
      vertical-align: top;
    }
  }

  & .dx-datagrid-content .dx-row.dx-freespace-row {
    border: none;
    background: white;

    & td {
      border: none;
    }
  }
}
