.subscriptionContainer {
  &__header {
    margin-bottom: 5px;
  }

  &__actionContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 5px;
  }
}