.subscriptionTextboxContainer {
    width: 100%;
    padding: 10px;

    &__defaultRateContainer {
        border: 1px solid #e8e8e8;
        border-radius: 5px;
        padding: 5px 10px;

        &__title {
            color: #737B8B;
        }


    }

    .defaultRate {
        margin-bottom: 10px;
        display: flex;
        gap: 5px;
        justify-content: space-between;
        align-items: center;

        .rate {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .rate__label {
                width: 50%;
                margin-right: 10px;
            }
            .rate__input {
                width: 50%;
            }
        }
    }

}

.subscriptionButtonMarginLeft {
    margin-left: 10px;
}
