@import '../../styles/colors';

.app-version {
  display: flex;
  color:color("primary");
  justify-content: center;
  align-items: flex-end;
  margin-top: auto;
  &__text {
    font-weight: 500;
    margin: 0;
    padding: 0;
    padding: 2rem 0;
  }
}
