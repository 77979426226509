@import '../../styles/shared';
@import "../../styles/colors";

.login-main {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: $padding-15;

  &__logo {
    max-width: 100%;
    width: 500px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 2rem 0 1rem 0;
    align-items: center;

    &__logo {
      height: 150px;
      width: 200px;
    }
  }

  &__title {
    font-size: 1.2rem !important;
    font-weight: 500 !important;
    padding: 0.5rem 0;
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    label {
      font-size: 0.8rem;
    }
  }

  &__form-title {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }

  &__cta {
    background-color: color("primar") !important;
    margin: 0 0 1rem !important;
    font-weight: 600;
    transition: all 0.2s linear;
    color: #fff;
    border-color: color("primar");

    &.dx-state-focused,
    &:hover,
    &:active &:focus {
      background-color: color("primar") !important;
    }
  }

  &__copyright {
    font-weight: 600;
  }

  @include desktop {
    grid-template-columns: 35%;
  }
}
